

<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="title">Manage Review Requests</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<!-- <Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column> -->
			<Column field="report_id" header="Report Id" filterField="report_id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Report Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['report_id'] }}
					</div>
				</template>
			</Column>
			<Column field="center_name" header="Center Name" filterField="center_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Center Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['center_name'] }}
						<div><i class="fa fa-phone"></i> {{ data['center_phone'] }}</div>
						<div><i class="fa fa-user"></i> {{ data['center_contact_person'] }}</div>
					</div>
				</template>
			</Column>
			<Column field="test_name" header="Test Name" filterField="test_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Test Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['test_name'] }}
					</div>
				</template>
			</Column>
			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['patient_name'] }}
					</div>
				</template>
			</Column>
			<Column field="reported_doctor" header="Reported Doctor" filterField="reported_doctor" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Reported Doctor" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['reported_doctor'] }}
					</div>
				</template>
			</Column>
			<Column field="review_time" header="Review Time" filterField="review_time" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Review Time" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ format(data['review_time'], "DD/MM/YY h:mmA") }}
					</div>
				</template>
			</Column>
			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Status" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['status'] }}
					</div>
				</template>
			</Column>
			<Column header="Options">
				<template #body="{ data }">
					<div class="tdata options">
						<button v-if="data?.status == 'pending'" class="btn btn-sm btn-outline-info" @click="showReview(data)">
							<i v-if="data?.department == 'radiology'" class="fa fa-eye"></i>
							<i v-if="data?.department == 'radiology_doc_notes'" class="fa fa-sticky-note"></i>
						</button>
						<button v-if="data?.showReset" class="btn btn-sm btn-outline-danger" @click="showReview(data)">
							<i class="fa-solid fa-arrow-rotate-left"></i>
						</button>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" id="exampleModal" ref="reviewModal" tabindex="-1" aria-labelledby="exampleModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">
							<div>Review Details</div>
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						
						<div class="container-fluid">
							<div class="row info">
								<div class="col-4">
									<div>Report Id: <b>{{ review?.report_id }}</b></div>
									<div>Center Name: <b>{{ review?.center_name }}</b></div>
									<div>Center Phone: <b>{{ review?.center_phone }}</b></div>
									<div>Contact Person: <b>{{ review?.center_contact_person }}</b></div>
								</div>
								<div class="col-4">
									<div>Test Name: <b>{{ review?.test_name }}</b></div>
									<div>Reported Doctor: <b>{{ review?.reported_doctor }}</b></div>
									<div>Review Time: <b>{{ format(review?.review_time, "DD/MM/YY h:mmA") }}</b></div>
									<div>Patient Name: <b>{{ review?.patient_name }}</b></div>
								</div>
								<div class="col-4">
									<div>
										History: <b>{{report?.history}}</b>
										<div v-if="historyAudioUrl"><audio style="width: 100%" :src="historyAudioUrl" controls></audio></div>
									</div>
									<div>
										<div v-if="report?.review_notes">
											Review Notes: <b>{{ report?.review_notes }}</b>
										</div>
										<div v-if="reviewAudioUrl"><audio style="width: 100%" :src="reviewAudioUrl" controls></audio></div>
									</div>
									<div v-if="report?.notes">
										Notes: <b>{{report?.notes}}</b>
									</div>
								</div>
							</div>
							<div class="col-12">
								Report: 
								<div v-html="report?.report"></div>
							</div>
						</div>

						<div class="clearfix" style="margin-top: 15px;">
							<div style="width: 200px; float: left;">
								<div style="margin-bottom: 10px;" v-for="image in allImages" :key="image">
									<img @click="showImage = image" style="max-width: 100%; cursor: pointer;"
										:src="apiOrigin + '/images/radiology/' + image" alt="">
								</div>
							</div>
							<div style="margin-left: 210px;">
								<img v-if="showImage" style="display: block; margin: auto; max-width: 100%"
									:src="apiOrigin + '/images/radiology/' + showImage" alt="">
							</div>
						</div>


					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						<button type="button" v-if="review?.department == 'radiology' && review?.status == 'pending'" @click="rejectReview" class="btn btn-danger">Reject</button>
						<button type="button" v-if="review?.department == 'radiology' && review?.status == 'pending'" @click="acceptReview" class="btn btn-success">Accept</button>
						<button type="button" v-if="review?.department == 'radiology' && review?.status == 'accepted'" @click="resetReview" class="btn btn-danger">Reset</button>
						
						<button type="button" v-if="review?.department == 'radiology_doc_notes'" @click="processDocNotesSubstandard" class="btn btn-danger">Substandard</button>
						<button type="button" v-if="review?.department == 'radiology_doc_notes'" @click="processDocNotes" class="btn btn-success">Process</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Toast from 'primevue/toast';
import { Modal } from 'bootstrap'
export default {
	components: {
		Toast
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: [],
			editId: 0,
			saving: false,
			reviewModal: null,
			deleteModal: null,
			lazyParams: {},
			filters: {
				'id': { value: '', matchMode: 'like' },
				'department': { value: '', matchMode: 'like' },
				'report_id': { value: '', matchMode: 'like' },
				'center_name': { value: '', matchMode: 'like' },
				'center_phone': { value: '', matchMode: 'like' },
				'center_contact_person': { value: '', matchMode: 'like' },
				'test_name': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'reported_doctor': { value: '', matchMode: 'like' },
				'review_time': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
			},
			review: null,
			report: null,
			reviewAudioUrl: null,
			historyAudioUrl: null,

			allImages: [],
			showImage: null,

			interval: 0,
		}
	},
	mounted() {
		this.reviewModal = new Modal(this.$refs.reviewModal);

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

		setTimeout(()=>{
			soc.on('review', this.onReview);
		}, 0)

		this.interval = setInterval(()=>{
			let now = new Date().getTime();

			for(let row of this.tableRecords){
				if(row.department == 'radiology' && row.status == 'accepted'){
					let diff = now - new Date(row.review_time).getTime();
					diff = diff/1000/60/60;
					if(diff > 1) row.showReset = true;
				}
			}

		}, 1000);

	},
	unmounted() {
		soc.off('review', this.onReview);
		clearInterval(this.interval);
	},
	methods: {
		onReview(){
			this.loadLazyData();
			
		},

		showReview(row) {
			this.review = row;
			this.report = null;

			this.allImages = [];
			this.showImage = null;

			this.reviewAudioUrl = null;
			this.historyAudioUrl = null;

			this.post("admin/load-xray_reports", {
				first: 0,
				rows: 1,
				sortField: "id",
				sortOrder: -1,
				filters: {
					id: {
						value: row.report_id,
						matchMode: '='
					}
				}
			}).then(res => {
				if (res.success) {
					let row = res.rows[0];
					this.report = row;
					
					if(row.review_audio_file){
						this.reviewAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + row.review_audio_file;
					}
					if(row.history_audio_file){
						this.historyAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + row.history_audio_file;
					}

					if(row.images){
						this.allImages = row.images.split(",");
						this.showImage = this.allImages[0];
					}


				}
			});
			this.reviewModal.show();
		},

		acceptReview(){
			this.post("admin/accept-xray-review", {id: this.review.id}).then(res=>{
				this.reviewModal.hide();
				this.$toast.add({ severity: 'success', summary: 'Accepted', detail: 'Review request has been accepted.', life: 3000 });
				this.loadLazyData();
			})
		},
		rejectReview(){
			this.post("admin/reject-xray-review", {id: this.review.id}).then(res=>{
				this.reviewModal.hide();
				this.$toast.add({ severity: 'info', summary: 'Rejected', detail: 'Review request has been rejected.', life: 3000 });
				this.loadLazyData();
			})
		},
		resetReview(){
			this.post("admin/reset-xray-review", {id: this.review.id}).then(res=>{
				this.reviewModal.hide();
				this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report reset is successful.', life: 3000 });
				this.loadLazyData();
			});
		},
		processDocNotes(){
			this.post("admin/process-doc-notes", {id: this.review.id}).then(res=>{
				this.reviewModal.hide();
				this.$toast.add({ severity: 'success', summary: 'Processed', detail: 'Doctor notes has been processed successfully.', life: 3000 });
				this.loadLazyData();
			})
		},
		processDocNotesSubstandard(){
			this.post("admin/process-doc-notes-substandard", {id: this.review.id}).then(res=>{
				this.reviewModal.hide();
				this.$toast.add({ severity: 'success', summary: 'Marked As Substandard', detail: 'Report has been marked as substandard.', life: 3000 });
				this.loadLazyData();
			})
		},
		
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			let dt = new Date();
			dt.setDate(dt.getDate()-5);
			
			dt = dt.toISOString();

			params.filters['review_time'] = {
				value: dt,
				matchMode: '>',
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			this.post("admin/load-review_requests", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped>
.info .col-4 > div {
	margin-bottom: 8px;
}
</style>